<template>
  <div ref="test" class="casexPc container">
    <img
      class="bannerImg"
      v-show="tabId === 1"
      src="../../assets/digital-1920.jpg"
      alt=""
    />
    <img
      class="bannerImg"
      v-show="tabId === 2"
      src="../../assets/D关于我们1920.png"
      alt=""
    />
    <div class="hander">
      <div class="log">
        <img src="../../assets/log.png" alt="" />
        <div>苏州探索数字技术有限公司</div>
      </div>
      <div class="tab">
        <div
          v-for="item in tabList"
          :key="item.id"
          class="item"
          @click="handleTab(item.id)"
        >
          <div class="title">{{ item.title }}</div>
          <div class="lis" v-show="tabId === item.id"></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-tab">
        <div
          v-for="item in tabList"
          :key="item.id"
          class="item"
          @click="handleTab(item.id)"
        >
          <div class="title" :class="tabId === item.id ? 'active' : ''">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div>违法和不良信息举报邮箱: tsszkf@exploredigit.com（工作日回复）</div>
      <a href="https://beian.miit.gov.cn/"
        >增值电信业务经营许可证： 苏ICP备2024060916号</a
      >
      <div>网络文化经营许可证： 苏网文 [2020] 5407-171</div>
      <div>地址： 苏州工业园区东旺路43号4号楼4楼4015室</div>
    </div>
  </div>
</template>

<script>
import { zoomElement } from "@/utils/zoom";
import { weChatShare } from "@/utils/wx";

export default {
  data() {
    return {
      // tab选中
      tabId: 1,
      // tab列表
      tabList: [
        {
          id: 1,
          title: "首页",
        },
        {
          id: 2,
          title: "关于我们",
        },
      ],
      zoom: 1,
    };
  },
  created() {
    if (
      !navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      this.$router.push({
        path: "/caseDigitalpc",
      });
      return false;
    }
    this.showShareTip();
  },
  mounted() {
    let zoomEl = this.$refs.test;
    console.log("zoomEl:" + zoomEl);
    // zoomElement(zoomEl);
  },
  beforeDestroy() {
    this.scroller.destroy(); //销毁
  },
  methods: {
    // tab切换
    handleTab(id) {
      this.tabId = id;
      window.scrollTo(0, 0);
    },
    // 显示分享提示
    showShareTip() {
      const params = {
        title: "苏州探索数字技术有限公司",
        desc: "交一个关心你的“AI朋友”",
        link: window.location.href,
        imgUrl:
          "https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/public/logo80%2A80.png",
      };
      weChatShare(params);
    },
  },
};
</script>
<style scoped lang="less">
.casexPc {
  transform-origin: 0 0;

  position: relative;
  img {
    width: 100%;
  }
  .hander {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 66px 0px 66px;

    .log {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: #ededed;
      img {
        width: 100px;
        margin-right: 24px;
      }
    }
    .tab {
      width: 130px;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #ededed;
        .title {
          margin-bottom: 3px;
        }
        .lis {
          width: 36px;
          height: 4px;
          background: #ededed;
          border-radius: 2px;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 292px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 78px;
    left: 0;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    font-size: 18px;

    a {
      color: #ffffff;
    }
    .bottom-tab {
      display: flex;
      justify-content: space-between;
      width: 320px;
      margin-bottom: 40px;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .title {
        color: #ffffff;
      }
      .active {
        font-weight: bold;
      }
    }
  }
}
</style>
